@import 'home';
@import 'about';
@import 'contact';

.bailey-container {
  text-align: center;
}

.main-content {
  padding-top: 10vh;
}

h1 {
  color: #00004d; 
}

a {
  color: #3673b0 !important;

  &:hover, &:active {
    color: #244d75 !important;
  } 
}

.navigation {
  margin-top: 1.5rem;

  a {
    margin-right: 2rem;
    margin-left: 2rem;
  }

}


//theming when lights are off
.spooky {
  color: #fff;
  background-color: #1a1a1a;
  height: 100vh;

  h1 {
    color: #b3b3ff;
  }

  a {
    color: #76a6d5 !important;
  
    &:hover, &:active {
      color: #4f8cc9 !important;
    } 
  }

  //override material styles
  .MuiFormLabel-root {
    color: #b3b3ff;
  }

  .MuiInputBase-root {
    color: #fff;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #b3b3ff;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(255, 255, 255, 0.87);
  }

  .MuiButton-root:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
