
.form-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.form-field {
    margin-left: "auto";
    margin-right: "auto";
    margin-bottom: "1rem";
    width: 300;
}