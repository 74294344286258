.lights {
    flex-direction: row;
    justify-content: space-between;
  
    p {
      padding-top: 3rem;
    }
    
    svg {
      height: 10rem;
      width: auto;
    }
  
    .light-ray {
      fill: none;
      stroke: #FBB03B; 
      stroke-width: 24.893; 
      stroke-miterlimit: 10;
    }
  
    .bulb {
      fill: #FBB03B;

      &:active, &:hover, &:focus {
        cursor: pointer;
        outline: none;
      }
    }
  
    .bulb-highlight {
      fill :#FFC06C;
    }
  
    .filament {
      stroke:#FFF;
    }
  
    .off {
      .light-ray {
        stroke: none;
      }
  
      .bulb {
        fill: #E6E6E6;
      }
  
      .bulb-highlight {
        fill: #CCC;
      }
  
      .filament {
        stroke: #000;
      }
    }

    .light1.focus {
      transition: all .2s ease-in-out;
      transform: rotate(135deg) scale(1.1);
    }
    .light2.focus {
      transition: all .2s ease-in-out;
      transform: rotate(-135deg) scale(1.1);
    }
  }

  .site-name {
    h1 {
      font-size: 6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  .credits {
    padding-top: 5rem;
    font-size: .75rem;
  
    p {
      margin-bottom: 0
    }
  }

  .name-container {
    display: inline-block;
    white-space: nowrap;
  }

  /* breakpoints */
  //tablet 723
  //small 933
  //large 1127

  @media (min-width: 723px) {
    .site-name {
      h1 {
        font-size: 10rem;
      }
    }

    .lights {
      svg {
        height: 15rem;
      }   
    }
    .first-name-container {
      margin-right: 3rem;
    }
  }
  